import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./../scss/global.scss"
import "./download.scss"
import { graphql } from "gatsby"
import {
  mdiAndroid,
  mdiAppleIos,
  mdiGithub,
  mdiLinux,
  mdiMicrosoftWindows,
} from "@mdi/js"
import Icon from "@mdi/react"
import { mdiDesktopMac } from "@mdi/js/commonjs/mdi"

const isLinux = false
const isWindows = true
const isMac = false
const isAndroid = false
const isIos = false
const ver = "9.0.7"

const dlUrl = (suffix: string, isSetup = false) =>
  `https://github.com/johannesjo/super-productivity/releases/download/v${ver}/superProductivity-${
    isSetup ? "Setup-" : ""
  }${ver}${suffix}`

const DownloadPage = ({ data }: any) => (
  <Layout isHideHeader={false}>
    <SEO
      title="Super Productivity – Download"
      description="Download Super Productivity."
    />
    <div className="container">
      <h1>Download</h1>

      <div className="dl-block-wrapper">
        <div className={"dl-block " + (isWindows ? "first" : "")}>
          <h2>
            <Icon path={mdiMicrosoftWindows} /> <span>Windows</span>
          </h2>
          <ul>
            <li className="fav-option">
              <a href="//www.microsoft.com/store/apps/9nhfvg8361tw?cid=storebadge&ocid=badge">
                Download from Microsoft Store
              </a>
            </li>
            <li className="fav-option">
              <a href={dlUrl(".exe", true)}>Download Setup.exe</a>
            </li>
            <li>
              <a href={dlUrl(".exe")}>Download PenDrive Version</a>
            </li>
            <li>
              <a href={dlUrl("-arm64.exe", true)}>Download ARM64 Setup.exe</a>
            </li>
            <li>
              <a href={dlUrl("-arm64.exe")}>Download ARM64 PenDrive Version</a>
            </li>
          </ul>
        </div>

        <div className={"dl-block " + (isMac ? "first" : "")}>
          <h2>
            <Icon path={mdiDesktopMac} /> <span>Mac</span>
          </h2>
          <ul>
            <li className="fav-option">
              <a href={dlUrl("-universal.dmg")}>Download DMG</a>
            </li>
            {/*<li>*/}
            {/*  <a href={dlUrl("-arm64.dmg")}>Download ARM64 DMG</a>*/}
            {/*</li>*/}
          </ul>
        </div>

        <div className={"dl-block " + (isLinux ? "first" : "")}>
          <h2>
            <Icon path={mdiLinux} /> <span>Linux</span>
          </h2>
          <p>Install snap via</p>
          <pre>
            <code>sudo snap install superproductivity</code>
          </pre>

          <ul>
            <li>
              <a href={dlUrl(".AppImage")}>Download AppImage</a>
            </li>
            <li>
              <a href={dlUrl(".x86_64.rpm")}>Download RPM</a>
            </li>
            <li>
              <a
                href={`https://github.com/johannesjo/super-productivity/releases/download/v${ver}/superProductivity_${ver}_amd64.deb`}
              >
                Download DEB
              </a>
            </li>
          </ul>
        </div>
        <div className={"dl-block " + (isAndroid ? "first" : "")}>
          <h2>
            <Icon path={mdiAndroid} /> <span>Android</span>
          </h2>
          <ul>
            <li className="fav-option">
              <a
                href={`https://play.google.com/store/apps/details?id=com.superproductivity.superproductivity`}
              >
                Download from Play Store
              </a>
            </li>
          </ul>
        </div>
        <div className={"dl-block " + (isIos ? "first" : "")}>
          <h2>
            <Icon path={mdiAppleIos} /> <span>IPhone & IPad</span>
          </h2>
          <ul>
            <li className="fav-option">
              <a href={`https://app.super-productivity.com/`}>
                Use the web app
              </a>
            </li>
          </ul>
        </div>
      </div>

      <h3
        style={{ marginBottom: "60px", marginTop: "40px" }}
        className="fav-option"
      >
        <a href="https://github.com/johannesjo/super-productivity/releases">
          – Download for any other platform –
        </a>

        <a
          style={{ display: "none" }}
          rel="me"
          href="https://mastodon.social/@superproductivity"
        >
          Mastodon
        </a>
      </h3>
    </div>
  </Layout>
)

export default DownloadPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
